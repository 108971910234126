<script lang="ts">
	import { Button as ButtonPrimitive } from 'bits-ui'

	import { cn } from '$lib/utils/shadcn'

	import { type Events, type Props, buttonVariants } from './index'

	type $$Props = Props
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	type $$Events = Events

	let className: $$Props['class'] = undefined
	export let variant: $$Props['variant'] = 'default'
	export let size: $$Props['size'] = 'default'
	export let builders: $$Props['builders'] = []
	export { className as class }
</script>

<ButtonPrimitive.Root
	class={cn(buttonVariants({ variant, size, className }))}
	{builders}
	type="button"
	{...$$restProps}
	on:click
	on:keydown
>
	<slot />
</ButtonPrimitive.Root>
